/* 
  Overrides for a material-ui@next feel.
*/
.Select.is-disabled .Select-control:before {
  background: transparent;
  background-size: 5px 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.42) 33%,
    transparent 0%
  );
  background-repeat: repeat-x;
  background-position: left top;
}
.Select.is-focused:not(.is-open) > .Select-control {
  border: none;
  box-shadow: none;
}
.Select.is-focused .Select-control:after {
  transform: scaleX(1);
}

.Select-control {
  align-items: baseline;
  background-color: white;
  border-collapse: separate;
  border-color: white;
  border-radius: 0;
  border-spacing: 0;
  border: none;
  color: black;
  color: rgba(0, 0, 0, 0.87);
  cursor: default;
  display: table;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 1rem;
  height: 36px;
  outline: none;
  overflow: hidden;
  position: relative;
  position: relative;
  width: 100%;
}
.Select-control:after {
  background-color: #2962ff;
  bottom: 0;
  content: '';
  height: 2px;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.Select-control:before {
  background-color: rgba(0, 0, 0, 0.42);
  bottom: 0;
  content: '';
  height: 1px;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.Select-control:hover {
  box-shadow: none;
}
.Select-control:hover:after:not(.is-disabled) {
  height: 2px;
  background-color: rgba(0, 0, 0, 0.87);
}

.Select-menu-outer {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #fff;
  border: none;
  margin-top: 8px;
  margin-bottom: 24px;
  z-index: 2;
}

.Select-option {
  background: none;
  box-sizing: content-box;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5em;
  overflow: hidden;
  padding-bottom: 12px;
  padding-top: 12px;
  text-overflow: ellipsis;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  white-space: nowrap;
}
.Select-option.is-selected,
.Select-option.is-focused {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}
.Select-option:hover:not(.is-disabled) {
  background-color: rgba(0, 0, 0, 0.12);
}
.Select-option.is-disabled {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.5;
}

.Select-noresults {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  cursor: default;
  display: block;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5em;
  padding-bottom: 12px;
  padding-top: 12px;
}

.Select--multi.has-value .Select-input > input {
  padding: 13px 0 12px;
}
.Select--multi .Select-value {
  position: relative;
  text-decoration: none;
  width: fit-content;
  border: none;
  padding: 5px 7px;
  line-height: 1.4;
  margin-bottom: 7px;
  cursor: default;
  outline: none;
  font-size: 0.8125rem;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  white-space: nowrap;
  border-radius: 16px;
  background-color: gainsboro;
}
.Select--multi .Select-value-icon {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.26);
  border-radius: 50%;
  color: #fafafa;
  display: flex;
  flex-shrink: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 1.2rem;
  font-weight: bolder;
  height: 20px;
  justify-content: center;
  opacity: 0.7;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 6px;
  top: 5px;
  user-select: none;
  width: 20px;
}
.Select--multi .Select-value-icon:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.26);
  color: #fafafa;
}
.Select--multi a.Select-value-label,
.Select--multi .Select-value-label {
  padding: 0px 27px 0px 3px;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}
.Select--multi a.Select-value-label:hover,
.Select--multi .Select-value-label:hover {
  text-decoration: none;
}
.Select-placeholder,
.Select--single > .Select-control .Select-value {
  padding-left: 0px;
}
.Select--multi .Select-value {
  margin-left: 0px;
  margin-right: 8px;
}
.Select--multi .Select-input {
  margin-left: 0px;
}
.Select-input {
  margin-left: 0px;
  padding-left: 0px;
}
